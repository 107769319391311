import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DMNLogoAligned from "./DMNLogoAligned";
import ArtLinkLogo from "../assets/artlinktext.png";
import { faGear } from "@fortawesome/free-solid-svg-icons";

interface TopBarProps {
  triggerModal: () => void;
  topBarRef: React.RefObject<HTMLDivElement>;
}

const TopBar = ({ triggerModal, topBarRef }: TopBarProps) => {
  return (
    <div
      ref={topBarRef}
      className="no-outline"
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
        marginTop: "20px",
      }}
    >
      {/* Logo */}
      <div
        style={{
          display: "flex",
          alignItems: "flex-end", // Aligns text with the bottom of the logo
        }}
      >
        <DMNLogoAligned />
        {/* <h1
          style={{
            width: "135px",
            height: "49px",
            top: "15px", // Adjusted to 25px as requested
            // left: "104px",
            gap: "0px",
            opacity: "1", // Changed from 0 to 1 so the text is visible
            fontFamily: "Poppins",
            fontSize: "38px",
            fontWeight: 900,
            lineHeight: "48.83px",
            letterSpacing: "-0.005em",
            textAlign: "left",
            position: "relative", // Ensure top/left are applied
          }}
        >
          ArtLink
        </h1> */}
        <img
          src={ArtLinkLogo}
          alt="logo"
          style={{
            marginLeft: "30px",
            marginBottom: "25px",
            width: "111px", // Keep the width fixed
            height: "25px", // Keep the height fixed
            transform: "scale(1.5)",
            transformOrigin: "center",
          }}
        />
      </div>
      <FontAwesomeIcon
        onClick={triggerModal}
        icon={faGear}
        size="2xl"
        aria-label="Settings"
        style={{ cursor: "pointer" }} // Ensures pointer cursor on icon
      />
    </div>
  );
};

export default TopBar;
