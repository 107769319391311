import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch } from "react";

interface DeletePiecePhotoModalProps {
  setIsDeleteModalVisible: Dispatch<React.SetStateAction<boolean>>;
  deletePhoto: () => Promise<void>;
}

const DeletePiecePhotoModal = ({
  setIsDeleteModalVisible,
  deletePhoto,
}: DeletePiecePhotoModalProps) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(31, 33, 40, 0.95)",
        padding: "20px",
        borderRadius: "20px",
        border: "5px solid #7800FF",
        minWidth: "250px",
        minHeight: "250px",
        zIndex: 1001, // Above the button container
      }}
    >
      <div
        style={{
          fontSize: "18px",
          fontWeight: 900,
          lineHeight: "34.88px",
          letterSpacing: "-0.005em",
          textAlign: "center",
        }}
      >
        Are you sure you want to delete this piece's photo?
      </div>
      <div id="hi" style={{ marginTop: "25px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => setIsDeleteModalVisible(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              cursor: "pointer",
            }}
          />
          <button
            style={{
              backgroundColor: "#7800ff",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              marginBottom: "50px",
              fontWeight: 700,
              fontSize: "16px",
              width: "150px",
              height: "45px",
            }}
            onClick={deletePhoto}
          >
            Confirm
          </button>
          <button
            style={{
              backgroundColor: "#7800ff",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              fontWeight: 700,
              fontSize: "16px",
              width: "150px",
              height: "45px",
            }}
            onClick={() => setIsDeleteModalVisible(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletePiecePhotoModal;
