export const createExpressAccount = async ({
  email,
  userId,
}: {
  email: string;
  userId: string;
}): Promise<{ accountId: string }> => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/stripe/create-express-account`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, userId: userId }),
    }
  );
  const data = await response.json();
  return data;
};

export const getAllPiecesByArtist = async (artistId: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/piece/all/${artistId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data;
};
