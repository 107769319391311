import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import { v4 as uuidv4 } from "uuid";
import { saveAs } from "file-saver";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type QRCodeGeneratorProps = {
  baseUrl: string;
  numberOfQrCodes: number;
};

const QRCodeGenerator = ({
  baseUrl,
  numberOfQrCodes,
}: QRCodeGeneratorProps) => {
  const generateCSV = () => {
    if (
      !Number.isInteger(numberOfQrCodes) ||
      numberOfQrCodes <= 0 ||
      numberOfQrCodes >= 1000
    ) {
      notifyError();
      return;
    }
    const newQRCodeData = Array.from({ length: numberOfQrCodes }, () => {
      const uniqueId = uuidv4();
      const dmnId = `#ART-${uniqueId.substring(0, 6).toUpperCase()}`;
      const url = `${baseUrl}/${uniqueId}?source=qr`;
      return { uniqueId, dmnId, url };
    });

    // Immediately use newQRCodeData for CSV content generation
    let csvContent = "UUID,DMN-ID,URL\n"; // Make sure the headers match the required columns

    newQRCodeData.forEach(({ uniqueId, dmnId, url }) => {
      // Ensure fields are added to the CSV in the correct column order
      csvContent += `"${uniqueId}","${dmnId}","${url}"\n`; // Enclose each field in quotes to handle special characters
    });

    // Create a Blob for the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const now = new Date();
    const year = now.getFullYear().toString().slice(2); // Get the last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Add 1 because JavaScript months start at 0
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    // saveAs(blob, `qr_codes.csv`);
    const fileName = `qr_codes-${month}-${day}-${year}-${hours}-${minutes}.csv`;
    saveAs(blob, fileName);
    notify();
  };

  const notify = () => {
    toast("CSV generated!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  };

  const notifyError = () => {
    toast.error(
      "Number of QR codes must be a whole number greater than 0 and less than 1000.",
      {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      }
    );
  };

  return (
    <div>
      <ToastContainer />
      <button
        onClick={() => generateCSV()}
        style={{
          display: "flex",
          justifyContent: "center",
          width: "200px",
          backgroundColor: "#7800ff",
          color: "white",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          fontWeight: "bold",
          marginTop: "30px",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "0px",
        }}
      >
        Generate & Download CSV
      </button>
    </div>
  );
};

export default QRCodeGenerator;
