import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import question from "../assets/question.png";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

interface EmptyStatePieceProps {
  mode: string;
  triggerModal: () => void;
  isPublicFacing: boolean;
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmptyStatePiece = ({
  mode,
  triggerModal,
  isPublicFacing,
  isModalVisible,
  setIsModalVisible,
}: EmptyStatePieceProps) => {
  const navigate = useNavigate();
  const inputStyle = {
    padding: "10px",
    margin: "10px 0",
    backgroundColor: "#1f2129",
    color: "white",
    border: "1px solid #444",
    borderRadius: "500px",
    width: "-webkit-fill-available",
    fontFamily: "Arial, sans-serif",
  };
  return (
    <div
      style={{
        position: "relative",
        color: "white",
        padding: "20px",
        margin: "10px", // Added margin around the entire component
        paddingBottom: "70px",
        paddingTop: "40px",
      }}
    >
      {/* <BackButton /> */}
      {/* Container to center the image */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={question}
          alt="example"
          style={{
            borderRadius: "16px",
            height: "350px",
            width: "350px",
            margin: "auto", // Centers the image
          }}
        />
      </div>

      {/* Text and Icon (left-aligned) */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          justifyContent: "space-between", // Added this
        }}
      >
        <div style={{ display: "flex", alignItems: "center", margin: 0 }}>
          {mode === "view" ? (
            <h2 id="title" style={{ margin: 0 }}>
              {""}
            </h2>
          ) : (
            <input
              type="text"
              placeholder="Type"
              value={""}
              style={inputStyle}
            />
          )}
        </div>
      </div>

      <h2 id="medium">No art piece has been linked yet.</h2>
      <h2 id="medium">
        {" "}
        Please use the scanner to link an art piece with a given QR Code!
      </h2>

      <br />

      <br />

      <br />

      {/* Button container */}
      <div
        style={{
          display: "flex",
          position: "fixed",
          justifyContent: "space-evenly",
          left: 0,
          bottom: 0,
          width: "100%",
          backgroundColor: "#1f2129",
          color: "white",
          textAlign: "center",
          padding: "10px 0", // Adjust as needed
          zIndex: 1000, // Ensure it's above other content
        }}
      >
        <button
          style={{
            fontWeight: "bold",
            width: "125px",
            backgroundColor: "#7800ff",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
          }}
          onClick={triggerModal}
          hidden={isPublicFacing}
        >
          Tag
        </button>
      </div>
      {isModalVisible && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(31, 33, 40, 0.95)",
            padding: "20px",
            borderRadius: "20px",
            border: "5px solid #7800FF",
            minWidth: "250px",
            minHeight: "250px",
            zIndex: 1001, // Above the button container
          }}
        >
          <FontAwesomeIcon
            icon={faTimes} // Make sure to import faTimes from '@fortawesome/free-solid-svg-icons'
            onClick={() => setIsModalVisible(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              cursor: "pointer",
            }}
          />
          <div id="hi" style={{ marginTop: "50px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column", // Stack buttons vertically
                alignItems: "center",
              }}
            >
              <button
                style={{
                  backgroundColor: "#7800ff",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  marginBottom: "50px", // Space between buttons
                  fontWeight: 700,
                  fontSize: "16px",
                  width: "150px",
                  height: "45px",
                }}
                onClick={() => setIsModalVisible(false)}
              >
                Unlink
              </button>
              <button
                style={{
                  backgroundColor: "#7800ff",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  fontWeight: 700,
                  fontSize: "16px",
                  width: "150px",
                  height: "45px",
                }}
                onClick={() => {
                  // Open up the QR code
                  navigate("/scanner");
                  setIsModalVisible(false);
                }}
              >
                Link New
              </button>
              {/* </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default EmptyStatePiece;
