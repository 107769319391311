import { LoadingOutlined } from "@ant-design/icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spin } from "antd";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bounce, toast, ToastContainer } from "react-toastify";

type NotificationSettings = {
  salesText: boolean;
  salesEmail: boolean;
  commentsText: boolean;
  commentsEmail: boolean;
  announcementText: boolean;
  announcementEmail: boolean;
};

const Settings: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<any>([]);
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSettings>({
      salesText: false,
      salesEmail: false,
      commentsText: false,
      commentsEmail: false,
      announcementText: false,
      announcementEmail: false,
    });

  const getUserByUid = async (uid: string) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user/${uid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const userResponse = await response.json();
      setDisplayName(userResponse.displayName);
      setEmail(userResponse.email);
      setPhoneNumber(userResponse.phoneNumber);
      setNotificationSettings(userResponse.notificationSettings || {});
      setUser(userResponse);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        await getUserByUid(uid);
      }
    });
  }, []);

  const inputStyle = {
    fontSize: "16px",
    padding: "10px",
    margin: "10px 0",
    backgroundColor: "#1f2129",
    color: "white",
    border: "1px solid #444",
    borderRadius: "500px",
    width: "-webkit-fill-available",
    fontFamily: "Arial, sans-serif",
  };

  // console.log("isLoading: ", isLoading);

  const handleSave = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user/settings/${user.uid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            displayName,
            email,
            phoneNumber,
            notificationSettings,
          }),
        }
      );
      const result = await response.json();
      console.log(result.message);
      toast("User settings updated!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
    } catch (error) {
      console.error("Error saving settings:", error);
    }
  };

  const disabledInputStyle = {
    ...inputStyle,
    color: "#a0a0a0", // Faded grey text color for disabled state
    cursor: "not-allowed", // Change cursor to indicate disabled state
  };

  return (
    <div
      style={{
        // backgroundColor: "#1c1c1e", // Dark background color
        padding: "20px",
        height: "100vh", // Full height
        color: "white",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <ToastContainer />
      <FontAwesomeIcon
        style={{ marginRight: "auto", marginLeft: "10px", marginTop: "10px" }}
        size="xl"
        icon={faAngleLeft}
        color="white"
        onClick={() => navigate("/home")}
      />
      <h1 style={{ color: "white", marginBottom: "30px" }}>Settings</h1>

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 100,
                  color: "#7800FF",
                }}
                spin
              />
            }
          />
        </div>
      ) : (
        <>
          <div style={{ marginBottom: "20px" }}>
            <input
              type="text"
              placeholder="Artist Name"
              style={inputStyle}
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
            <input
              type="email"
              placeholder="Email"
              style={inputStyle}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              disabled={true}
              type="tel"
              placeholder="Phone Number"
              style={disabledInputStyle}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2 style={{ marginBottom: "10px", fontSize: "18px" }}>
              Notification Preferences
            </h2>

            {[
              { label: "Sales", name: "sales" },
              { label: "Comments", name: "comments" },
              { label: "Announcements", name: "announcements" },
            ].map((item) => (
              <div
                key={item.name}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <label style={{ fontSize: "16px" }}>{item.label}</label>
                <div>
                  <input
                    checked={
                      notificationSettings[
                        `${item.name}Text` as keyof NotificationSettings
                      ] || false
                    }
                    onChange={(e) =>
                      setNotificationSettings((prev) => ({
                        ...prev,
                        [`${item.name}Text` as keyof NotificationSettings]:
                          e.target.checked,
                      }))
                    }
                    type="checkbox"
                    id={`${item.name}-text`}
                    style={{ marginRight: "10px" }}
                  />
                  <label
                    htmlFor={`${item.name}-text`}
                    style={{ marginRight: "20px" }}
                  >
                    Text
                  </label>
                  <input
                    checked={
                      notificationSettings[
                        `${item.name}Email` as keyof NotificationSettings
                      ] || false
                    }
                    onChange={(e) =>
                      setNotificationSettings((prev) => ({
                        ...prev,
                        [`${item.name}Email` as keyof NotificationSettings]:
                          e.target.checked,
                      }))
                    }
                    type="checkbox"
                    id={`${item.name}-email`}
                  />
                  <label htmlFor={`${item.name}-email`}>Email</label>
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "120px",
            }}
          >
            <button
              onClick={handleSave}
              style={{
                width: "125px",
                backgroundColor: "#7800ff",
                color: "white",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                fontWeight: "bold",
              }}
            >
              Save
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Settings;
