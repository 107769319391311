interface TagAndSubmitButtonsProps {
  triggerModal: () => void;
  mode: string;
  handleSaveChanges: () => void;
  createTag: () => void;
}

const TagAndSubmitButtons = ({
  triggerModal,
  mode,
  handleSaveChanges,
}: TagAndSubmitButtonsProps) => {
  return (
    <>
      <button
        style={{
          fontWeight: "bold",
          width: "125px",
          backgroundColor: "#7800ff",
          color: "white",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
        }}
        onClick={() => {
          if (mode === "edit") {
            handleSaveChanges();
          } else {
            triggerModal();
          }
        }}
      >
        {mode === "edit" ? "Save" : "Tag"}
      </button>
    </>
  );
};

export default TagAndSubmitButtons;
