// Index.js (or whichever file you are setting up the rendering)

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import RoutesWithTransitions from "./RoutesWithTransitions";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AuthProvider } from "./context/AuthContext";
import { Helmet } from "react-helmet";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || "");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    {/* <Helmet>
      <title>ArtLinks</title>
      <meta property="og:image" content="https://i.imgur.com/dlJvkM6.png" />
    </Helmet> */}
    <Elements stripe={stripePromise}>
      <AuthProvider>
        <BrowserRouter>
          <RoutesWithTransitions />
        </BrowserRouter>
      </AuthProvider>
    </Elements>
  </>
);

reportWebVitals();
