import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EyeIcons from "./EyeIcon";
import WhiteCheckbox from "./WhiteCheckbox";
import { getTotalViews } from "../lib/utils";

interface ArtPiecesProps {
  artPieces: any; //TODO: remove these any's
  handlePiece: (id: string) => void;
}

const ArtPieces = ({ artPieces, handlePiece }: ArtPiecesProps) => {
  if (artPieces.length === 0) {
    <div style={{ textAlign: "center", padding: "20px" }}>
      You don't have any art pieces yet.
    </div>;
  }
  return artPieces.map((piece: any) => {
    return (
      <div
        key={piece._id}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <h3
          onClick={() => handlePiece(piece._id)}
          style={{ cursor: "pointer", textDecoration: "underline" }}
        >
          {piece.name}
          {piece.linkId && (
            <FontAwesomeIcon
              style={{ marginLeft: "5px" }}
              icon={faLink}
              color="#7800ff"
            />
          )}
        </h3>
        <EyeIcons />
        {/* TODO: Increment views */}
        <h4 style={{ marginLeft: "5px" }}>{getTotalViews(piece) || "0"}</h4>
        {piece.comments?.length > 0 && (
          <h4 style={{ marginLeft: "10px" }}>
            {piece.comments?.length} comments
          </h4>
        )}
      </div>
    );
  });
};

export default ArtPieces;
