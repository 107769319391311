import "./style.css";
import { Navbar } from "../Navbar";
import DMNICON from '../../assets/DMNICON.png'
import artistwhite from '../../assets/artistwhite.png'
import connectwhite from '../../assets/connectwhite.png'
import gallerywhite from '../../assets/gallerywhite.png'
import shakewhite from '../../assets/shakewhite.png'
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Col, Row } from "antd";
import { title } from "process";

const productAndFeatures = [
  {
    title: "Artist",
    description: "Streamlined selling process, enhanced discoverability, and professional tools for artists.",
    buttonText: "Try Free",
    img: artistwhite,
  },
  {
    title: "Collector",
    description: "Manage collections and deal flow, discover new works, and facilitate transactions effortlessly.",
    buttonText: "Coming Soon",
    img: shakewhite,
  },
  {
    title: "Gallery",
    description: "Gallery management, sales, event submissions, and artist collaboration all in one.",
    buttonText: "Coming Soon",
    img: gallerywhite,
  },
  {
    title: "Marketplace",
    description: `Manage collections and deal flow, discover new works, and facilitate transactions effortlessly.`,
    buttonText: "Coming Soon",
    img: connectwhite,
  }
]

export const LandingPage = (): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);


  return (
    <>
      <div className="landing-page" id="landing-page">
        <div className="background-wrp" />
        <Navbar />

        <div className="page-content-container">
          <div className="hero-section">
            <div className="content-container">
              <h1 className="content-title">ArtLink</h1>
              <h1 className="content-subtitle">A marketplace for the global art community.</h1>
              <p className="content-details">ArtLink provides tools for the art world to connect, engage, and transact.</p>
              <button className="content-button">Explore ArtLink</button>
            </div>
          </div>

          <div className="product-feature-section" id="product" >
            <div className="product-feature-title">Product & Features</div>
            <div className="product-feature-description">A unified solution for artists, galleries, collectors, and consumers—manage, sell, and connect seamlessly.</div>

            <Row gutter={[200, 50]} className="features-card-container">
              {
                productAndFeatures.map((product, index) => {
                  return (
                    <Col span={24} xs={24} lg={24} xl={12} className="feature-card-wrapper" key={index}>
                      <div className="feature-card">
                        <div className="feature-title">{product.title}</div>
                        <div className="feature-body">
                          <div className="img-container">
                            <img src={product.img} alt="artist" className="feature-img" />
                          </div>

                          <div className="text-container">
                            <div className="feature-description">{product.description}</div>
                            <button className="feature-button">{product.buttonText}</button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                })
              }
            </Row>
          </div>

          <div className="artLink-concierge-section">
            <div className="artLink-concierge-logo">
              <img src={DMNICON} alt="DMNICON" className="concierge-img" />
            </div>
            <div className="artLink-concierge-title">ArtLink Concierge</div>
            <div className="artLink-concierge-description">ArtLink Concierge offers a premium, white-glove art service that seamlessly blends technology and media to sell artwork in innovative and creative ways. <br /> <br /> As an ArtLink Concierge client, you'll gain exclusive access to our expert advisory and media teams. We’ll appraise, catalog, and list your collections on ArtLink, while collaborating with you to craft campaigns and strategies designed to elevate your profile as an artist.</div>
            <button className="concierge-button">Join Waitlist</button>
          </div>
        </div>
      </div>
    </>
  );
};
