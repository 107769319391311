import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPalette } from "@fortawesome/free-solid-svg-icons";
import { BsFillEyeFill } from "react-icons/bs";

interface GroupedPiecesByLocationProps {
  groupedPieces: Array<{
    location: string;
    pieces: Array<{
      _id: string;
      name: string;
      comments: Array<any>;
    }>;
  }>;
  handlePiece: (piece: any) => void;
  getTotalViews: (piece: any) => number;
}

const GroupedPiecesByLocation: React.FC<GroupedPiecesByLocationProps> = ({
  groupedPieces,
  handlePiece,
  getTotalViews,
}) => {
  return (
    <div>
      {groupedPieces.map((group) => (
        <div
          key={group.location}
          style={{ textAlign: "left", marginBottom: "10px" }}
        >
          <div
            style={{
              color: "#7800FF",
              fontSize: "18px",
              fontWeight: 900,
              lineHeight: "22.32px",
              wordWrap: "break-word",
              marginBottom: "30px",
            }}
          >
            <FontAwesomeIcon icon={faLocationDot} color={"#7800FF"} />{" "}
            {group.location}
            {group.pieces.map((piece) => (
              <div key={piece._id} style={{ marginLeft: "10px" }}>
                <h3
                  onClick={() => handlePiece(piece)}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    margin: 0,
                    marginTop: "5px",
                    color: "white",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPalette}
                    color="white"
                    style={{ marginRight: "5px" }}
                  />
                  {piece.name}
                </h3>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <BsFillEyeFill
                    style={{ marginRight: "5px" }}
                    color="white"
                    size={24}
                  />
                  <h4 style={{ marginLeft: "5px", margin: 0, color: "white" }}>
                    {getTotalViews(piece) || "0"}
                  </h4>
                </div>
                <div style={{ marginTop: "5px", marginBottom: "30px" }}>
                  <h4 style={{ margin: 0, color: "white" }}>
                    {piece.comments.length + " comment(s)"}
                  </h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default GroupedPiecesByLocation;
