import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, useState } from "react";
import Lottie, { LottieProps } from "react-lottie";
import { useNavigate } from "react-router-dom";
import { check } from "../components/checkmark";

interface OnboardingModalProps {
  setIsModalVisible: Dispatch<React.SetStateAction<boolean>>;
  user: any;
}

const OnboardingModalDemo = ({
  setIsModalVisible,
  user,
}: OnboardingModalProps) => {
  const [artistName, setArtistName] = useState("");
  const [email, setEmail] = useState("");
  const [isUserCreated, setIsUserCreated] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const inputStyle = {
    padding: "10px",
    margin: "10px 0",
    backgroundColor: "#1f2129",
    color: "white",
    border: "1px solid #444",
    borderRadius: "500px",
    width: "-webkit-fill-available",
    fontFamily: "Arial, sans-serif",
    fontSize: "16px", // Ensure font size is at least 16px to prevent zoom on mobile
  };

  const handleSignUp = async () => {
    // Simulate a sign-up process
    setIsUserCreated(true);
  };

  const eventListeners = [
    {
      eventName: "complete", // Listen for the 'complete' event
      callback: () => navigate("/home"), // Navigate when the animation is complete
    },
  ];
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(31, 33, 40, 0.95)",
        padding: "20px",
        borderRadius: "20px",
        border: "5px solid #7800FF",
        minWidth: "250px",
        minHeight: "250px",
        zIndex: 1001, // Above the button container
      }}
    >
      <div id="hi" style={{ marginTop: "50px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column", // Stack buttons vertically
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faTimes} // Make sure to import faTimes from '@fortawesome/free-solid-svg-icons'
            onClick={() => setIsModalVisible(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              cursor: "pointer",
            }}
          />
          <input
            onChange={(e) => {
              const newName = e.target.value;
              setArtistName(newName); // Always update the artistName with the new input
            }}
            type="text"
            placeholder="Artist Name"
            style={inputStyle}
          />
          <input
            onChange={(e) => {
              const newEmail = e.target.value;
              setEmail(newEmail);
            }}
            type="email"
            placeholder="Enter email"
            style={inputStyle}
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            onInvalid={(e) => {
              e.currentTarget.setCustomValidity(
                "Please enter a valid email address."
              );
            }}
            onInput={(e) => {
              e.currentTarget.setCustomValidity("");
            }}
          />
          {error && (
            <div
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              {error}
            </div>
          )}
          {!isUserCreated ? (
            <button
              disabled={artistName === "" || error !== "" || email === ""}
              style={{
                backgroundColor:
                  artistName === "" || error !== "" ? "grey" : "#7800ff",
                color: "white",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                fontWeight: 700,
                fontSize: "16px",
                width: "150px",
                height: "45px",
                marginTop: "20px",
              }}
              onClick={() => {
                handleSignUp();
              }}
            >
              Enter
            </button>
          ) : (
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: check,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={100}
              width={100}
              eventListeners={eventListeners as LottieProps["eventListeners"]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingModalDemo;
