import React from "react";

const ViewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <g stroke="#ffffff">
        <circle cx="12" cy="12" r="2.5" />
        <path d="m18.2265 11.3805c.1287.2535.193.3802.193.6195s-.0643.366-.193.6195c-.6264 1.2338-2.4145 3.8805-6.2265 3.8805-3.81201 0-5.60008-2.6467-6.22652-3.8805-.12867-.2535-.193-.3802-.193-.6195s.06433-.366.193-.6195c.62644-1.2338 2.41451-3.8805 6.22652-3.8805 3.812 0 5.6001 2.6467 6.2265 3.8805z" />
        <g stroke-linecap="round">
          <path d="m17.5 3.5h.2c1.7913 0 2.687 0 3.2435.5565.5565.55649.5565 1.45216.5565 3.2435v.2" />
          <path d="m17.5 20.5h.2c1.7913 0 2.687 0 3.2435-.5565s.5565-1.4522.5565-3.2435v-.2" />
          <path d="m6.5 3.5h-.2c-1.79134 0-2.68701 0-3.2435.5565-.5565.55649-.5565 1.45216-.5565 3.2435v.2" />
          <path d="m6.5 20.5h-.2c-1.79134 0-2.68701 0-3.2435-.5565-.5565-.5565-.5565-1.4522-.5565-3.2435v-.2" />
        </g>
      </g>
    </svg>
  );
};

export default ViewIcon;
