import React from "react";
import ImageUploading from "react-images-uploading";

type ImageUploadProps = {
  onSave?: (dataUrl: string) => void;
  setImage?: React.Dispatch<React.SetStateAction<string>>;
};

const ImageUpload: React.FC<ImageUploadProps> = ({ setImage, onSave }) => {
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  //   @ts-ignore
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
    onSave && onSave(imageList[0].data_url);
    setImage && setImage(imageList[0].data_url);
  };

  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button
              style={isDragging ? { color: "red" } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              Click or Drop here
            </button>
            &nbsp;
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            {/* {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image["data_url"]} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  <button onClick={() => onImageUpdate(index)}>Update</button>
                  <button onClick={() => onImageRemove(index)}>Remove</button>
                </div>
              </div>
            ))} */}
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export default ImageUpload;
