import React from "react";
import PropTypes from "prop-types";
import "./ResponsiveImage.css"; // Import the CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";

const ResponsiveImage = ({ src, alt, onToggle, isSold }: any) => {
  return (
    <div
      className={
        isSold
          ? "responsive-image-container-sold"
          : "responsive-image-container"
      }
    >
      <img src={src} alt={alt} className="responsive-image" />
      <FontAwesomeIcon
        className={isSold ? "toggle-icon-sold" : "toggle-icon"}
        icon={faExpand}
        onClick={onToggle}
      />
    </div>
  );
};

export default ResponsiveImage;
