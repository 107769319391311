import dmnLogo from "../assets/DMNICON.png"; // adjust the path as needed

const DMNLogo = () => {
  return (
    <div>
      <img
        src={dmnLogo}
        alt="DMN Logo"
        style={{
          maxWidth: "180px",
          margin: "auto",
          display: "block",
          transform: "translateY(-10px)",
          marginBottom: "20px",
        }}
      />
    </div>
  );
};

export default DMNLogo;
