import React from "react";
import { useNavigate } from "react-router-dom";

const DeleteConfirmation = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // This will take the full height of the viewport
        textAlign: "center", // This will center the text horizontally
      }}
    >
      <div
        style={{
          color: "white",
          fontSize: "25px",
          fontWeight: 900,
          lineHeight: "34.88px",
          wordWrap: "break-word",
          marginBottom: "20px", // Adds space between text and button
        }}
      >
        Piece successfully deleted
      </div>
      <button
        style={{
          padding: "12px 40px", // Shorthand for paddingTop, paddingRight, paddingBottom, paddingLeft
          background: "#7800FF",
          borderRadius: "10px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          border: "none",
          color: "white",
          fontSize: "15px",
          fontWeight: 700,
          lineHeight: "20.93px",
          wordWrap: "break-word",
        }}
        onClick={() => {
          navigate("/home");
        }}
      >
        Back
      </button>
    </div>
  );
};

export default DeleteConfirmation;
