import React from "react";
import ViewIcon from "./ViewIcon";

const EyeIcons = () => {
  return (
    <div style={{ marginLeft: "10px", marginTop: "5px" }}>
      <ViewIcon />
    </div>
  );
};

export default EyeIcons;
