import React, { useEffect, useState } from "react";
import DMNLogo from "../components/DMNLogo";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Lottie, { LottieProps } from "react-lottie";
import { check } from "../components/checkmark";

type UserInformation = {
  uid: string;
  email: string;
  displayName: string;
  phoneNumber: string | null;
};

const SignUp = () => {
  const [userInformation, setUserInformation] = useState<UserInformation>({
    uid: "",
    email: "",
    displayName: "",
    phoneNumber: "",
  });
  const [isUserCreated, setIsUserCreated] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserInformation({
          ...userInformation,
          uid: user.uid,
          phoneNumber: user.phoneNumber,
        });
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
      } else {
        // User is signed out
      }
    });
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const navigate = useNavigate();
  const handleSignUp = async () => {
    try {
      const body = {
        uid: userInformation.uid,
        email: userInformation.email,
        displayName: userInformation.displayName,
        phoneNumber: userInformation.phoneNumber,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user/create/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(body),
        }
      );
      if (response.ok) {
        setIsUserCreated(true);
      }
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  const inputStyle = {
    fontSize: "16px",
    padding: "10px",
    margin: "10px 0",
    backgroundColor: "#1f2129",
    color: "white",
    border: "1px solid #444",
    borderRadius: "500px",
  };

  const eventListeners = [
    {
      eventName: "complete", // Listen for the 'complete' event
      callback: () => navigate("/home"), // Navigate when the animation is complete
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "#1f2129",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DMNLogo />

      {/* Name Input */}
      <input
        type="text"
        placeholder="Display Name"
        style={inputStyle}
        onChange={(e) => {
          setUserInformation({
            ...userInformation,
            displayName: e.target.value,
          });
        }}
      />
      {/* Email Input */}
      <input
        type="email"
        placeholder="Email"
        style={inputStyle}
        onChange={(e) => {
          setUserInformation({ ...userInformation, email: e.target.value });
        }}
      />

      {/* Save Button */}
      {!isUserCreated ? (
        <button
          onClick={handleSignUp}
          style={{
            fontSize: "16px",
            marginTop: "20px",
            backgroundColor: "#7800ff",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
          }}
        >
          Sign Up
        </button>
      ) : (
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: check,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={100}
          width={100}
          eventListeners={eventListeners as LottieProps["eventListeners"]}
        />
      )}
    </div>
  );
};

export default SignUp;
