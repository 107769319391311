import { Button, Input } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import "./Quantity.css";

interface QuantityProps {
  quantity: number;
  setQuantity: (value: number) => void; // Generic function to set quantity
}

export default function Quantity({ quantity, setQuantity }: QuantityProps) {
  const increment = () => setQuantity(quantity + 1);
  const decrement = () => setQuantity(Math.max(1, quantity - 1));

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 1) {
      setQuantity(value);
    }
  };

  return (
    <div
      className="quantity-container"
      style={{ marginTop: "10px", marginBottom: "10px" }}
    >
      <label className="quantity-label">Quantity</label>
      <div className="quantity-controls">
        <Button
          type="default"
          icon={<MinusOutlined />}
          onClick={decrement}
          disabled={quantity === 1}
          aria-label="Decrease quantity"
          style={{
            backgroundColor: quantity === 1 ? "#d9d9d9" : "#7800ff",
            color: quantity === 1 ? "black" : "#fff",
            borderColor: quantity === 1 ? "#d9d9d9" : "#7800ff",
          }}
        />
        <Input
          type="number"
          min="1"
          value={quantity}
          onChange={handleInputChange}
          style={{
            width: "60px",
            textAlign: "center",
            backgroundColor: "#1f2129",
            color: "white",
          }}
          aria-label="Quantity"
        />
        <Button
          style={{
            backgroundColor: "#7800ff",
            color: "#fff",
            borderColor: "#7800ff",
          }}
          type="default"
          icon={<PlusOutlined />}
          onClick={increment}
          aria-label="Increase quantity"
        />
      </div>
    </div>
  );
}
