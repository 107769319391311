import React, { useEffect, useRef, useState } from "react";
import DMNLogoAligned from "../components/DMNLogoAligned";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import OnboardingModal from "../components/OnboardingModal";
import OnboardingModalDemo from "../components/OnboardingModalDemo";
import { motion } from "framer-motion";

const OnboardingDemo: React.FC = () => {
  const topBarRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState<any>([]); // TODO: make a type for user

  //   const getUserByUid = async (uid: string) => {
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_BASE_URL}/user/${uid}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Accept: "application/json",
  //             "Access-Control-Allow-Origin": "*",
  //           },
  //         }
  //       );
  //       if (!response.ok) {
  //         console.error("Error fetching user:", response);
  //         return;
  //       } else {
  //         navigate("/home");
  //       }
  //       const userResponse = await response.json();

  //       setUser(userResponse);
  //       return userResponse;
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   useEffect(() => {
  //     const auth = getAuth();
  //     onAuthStateChanged(auth, (user) => {
  //       if (user) {
  //         // User is signed in, see docs for a list of available properties
  //         // https://firebase.google.com/docs/reference/js/auth.user
  //         const uid = user.uid;
  //         setUser(user);
  //         getUserByUid(uid);
  //       } else {
  //       }
  //     });
  //   }, []);

  useEffect(() => {
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    if (topBarRef.current) {
      // @ts-ignore
      topBarRef.current.tabIndex = -1;
      // @ts-ignore
      topBarRef.current.focus();
    }
  }, []);

  const triggerModal = () => {
    setShowModal(!showModal);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 1, // Delay between each child animation
      },
    },
  };

  const childVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5, // Duration of the fade in
      },
    },
  };

  return (
    <motion.div
      style={{
        backgroundColor: "#1f2129",
        color: "white",
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingTop: "25px",
      }}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {/* Top Bar */}
      <div
        ref={topBarRef}
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
          outline: "none",
        }}
      >
        {/* Logo */}
        <div>
          <DMNLogoAligned />
        </div>
      </div>

      {/* Main Content */}
      <motion.div
        style={{
          flex: 1,
          flexDirection: "column",
          margin: "20px",
        }}
      >
        <motion.h1 variants={childVariants}>
          Hi, <br /> Welcome to ArtLink!
        </motion.h1>
        <motion.h1 variants={childVariants}>
          A place for your art to live...
        </motion.h1>
        <motion.h1 variants={childVariants}>
          ... and for you to thrive.
        </motion.h1>
        <motion.button
          style={{
            width: "125px",
            backgroundColor: "#7800ff",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
          }}
          variants={childVariants}
          onClick={() => {
            triggerModal();
          }}
        >
          Enter
        </motion.button>
      </motion.div>
      {showModal && (
        <OnboardingModalDemo user={user} setIsModalVisible={setShowModal} />
      )}
    </motion.div>
  );
};

export default OnboardingDemo;
