import QRCodeStyling from "styled-qr-code";

export const generateStyledQRCode = async (url) => {
  const qrCode = new QRCodeStyling({
    width: 450, // Making it larger than needed for better quality
    height: 450,
    type: "canvas", // Using canvas for data URL generation
    data: url,
    dotsOptions: {
      color: "#000000",
      type: "dots", // Using dots style as requested
    },
    backgroundOptions: {
      color: "#ffffff",
    },
    qrOptions: {
      errorCorrectionLevel: "L", // Highest error correction for better reliability
    },
  });

  // Create a temporary container
  const container = document.createElement("div");
  container.style.display = "none";
  document.body.appendChild(container);

  // Append QR code to container
  qrCode.append(container);

  // Get canvas element
  const canvas = container.querySelector("canvas");

  // Convert to data URL
  const dataUrl = canvas.toDataURL("image/png");

  // Clean up
  document.body.removeChild(container);

  return dataUrl;
};
