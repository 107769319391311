import React from "react";
import "./RibbonTest.css";

const SoldRibbon = () => {
  return (
    <div className="ribbon ribbon-top-right">
      <span>SOLD</span>
    </div>
  );
};

export default SoldRibbon;
