// You get here by literally opening up a piece.
import React, { CSSProperties, useEffect, useState } from "react";
import { BsFillPencilFill, BsCheck2Circle } from "react-icons/bs";
import { FaRegTrashCan } from "react-icons/fa6";
import { LoadingOutlined } from "@ant-design/icons";
import { InputNumber, Select, Space, Spin, Switch, Tag } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faLink,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmptyStatePiece from "../components/EmptyStatePiece";
import LinkModal from "../components/LinkModal";
import TagAndSubmitButtons from "../components/TagAndSubmitButtons";
import DeletePieceModal from "../components/DeletePieceModal";
import FsLightbox from "fslightbox-react";
import ResponsiveImage from "../components/ResponsiveImage";
import LocationInput from "./LocationInput";
import {
  countComments,
  formatPriceWithCommas,
  generateLatestLocation,
  generateOGMetadata,
  generateUrl,
  getElementTextContent,
  getTotalViews,
  listLatestLocationForCustomerFacingPiece,
  pieceOptions,
} from "../lib/utils";
import { useAuth } from "../context/AuthContext";
import "./InputStyles.css";
import RibbonTest from "../components/RibbonTest";
import ViewIcon from "../components/ViewIcon";
import exportedFont from "../components/AnotherFontExport";
import QRCode from "qrcode";
import { jsPDF } from "jspdf";
import newerDmnLogo from "../assets/DMNICON.png";
import artLinkLogo from "../assets/artlinkblack.png";
import { DMN_URL } from "../lib/constants";
import { Helmet } from "react-helmet";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Quantity from "../components/Quantity";
import DeletePiecePhotoModal from "../components/DeletePiecePhotoModal";
import CropperModal from "../components/CropperModal";
import { AiOutlinePicture } from "react-icons/ai";
import { generateStyledQRCode } from "./styledQRCode";

interface PieceInformationWithIdProps {
  isPublicFacing: boolean;
  isLinked: boolean;
}

const PieceInformationWithId = ({
  isPublicFacing,
}: PieceInformationWithIdProps) => {
  const { pieceId, linkId } = useParams();
  const { currentUser } = useAuth();

  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDeletePhotoModalVisible, setIsDeletePhotoModalVisible] =
    useState(false);
  const [artPieceInformation, setArtPieceInformation] = useState<any>(null);
  const [taggedEntity, setTaggedEntity] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState("view");
  const [locationSearch, setLocationSearch] = useState<any>({});
  const [suggestions, setSuggestions] = useState<any>([]);
  const [hasSelectedLocation, setHasSelectedLocation] = useState(false);
  const [artistInformation, setArtistInformation] = useState<any>(null);
  const [isForSale, setIsForSale] = useState(true);
  const [toggler, setToggler] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [hasLocation, setHasLocation] = useState(true);
  const [user, setUser] = useState<any>([]);
  const [showCropper, setShowCropper] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const source = queryParams.get("source");
  const toggleMode = () => {
    setMode(mode === "view" ? "edit" : "view");
  };

  const fetchLinkedImage = async () => {
    try {
      // setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/piece/link/${linkId}?source=${source}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (!response.ok) {
        setIsLoading(false);
        setArtPieceInformation(null);
      }
      const result = await response.json();
      setArtPieceInformation(result);
      // remove ?source=qr from the url
      // Get the current URL
      const url = new URL(window.location.href);

      // Get the search parameters
      const params = new URLSearchParams(url.search);

      // Delete the 'source' parameter if it is 'qr'
      if (params.get("source") === "qr") {
        params.delete("source");

        // Create the new URL without the 'source' parameter
        const newUrl = `${url.origin}${url.pathname}${params.toString() ? `?${params.toString()}` : ""
          }`;

        // Update the URL without reloading the page
        window.history.replaceState({}, document.title, newUrl);
      }
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  const fetchImage = async () => {
    try {
      // setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/piece/${pieceId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (!response.ok) {
        setIsLoading(false);
        setArtPieceInformation(null);
      }
      const result = await response.json();
      if (
        listLatestLocationForCustomerFacingPiece(result.locationHistory)
          .location.name === "Artist's Collection"
      ) {
        console.log("REACHED HERE");
        setHasLocation(false);
        setHasSelectedLocation(true);
        setLocationSearch({
          name: "Artist's Collection",
        });
      } else {
        setHasLocation(true);
        setHasSelectedLocation(true);
      }
      if (result.linkId)
        setTaggedEntity(result.linkId?.substring(0, 6).toUpperCase() || "");

      if (result.locationHistory.length > 0) {
        const latest = generateLatestLocation(result.locationHistory);
        setArtPieceInformation({
          ...result,
          location: {
            ...latest.location,
            endDate: latest.endDate,
          },
        });
      } else {
        setArtPieceInformation(result);
      }
      // setIsLoading(false);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  const fetchArtistInformation = async () => {
    try {
      // setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user/artist/${artPieceInformation.artistUid}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch artist information");
      }
      const artist = await response.json();
      setArtistInformation(artist);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching artist information: ", error);
    }
  };

  const updatePieceInformation = async () => {
    try {
      if (!artPieceInformation.units || artPieceInformation.units === "") {
        setValidationError("Please provide units for the piece.");
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/piece/update/${pieceId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(artPieceInformation),
        }
      );
      if (!response.ok) {
        console.error("Error updating piece");
      } else {
        notifySavedPiece();
        const result = await response.json();
        if (
          listLatestLocationForCustomerFacingPiece(result.locationHistory)
            .location.name === "Artist's Collection"
        ) {
          console.log("REACHED HERE");
          setHasLocation(false);
          setHasSelectedLocation(true);
          setLocationSearch({
            name: "Artist's Collection",
          });
        } else {
          setHasLocation(true);
          setHasSelectedLocation(true);
        }
        if (result.locationHistory.length > 0) {
          // const latest = generateLatestLocation(result.locationHistory);
          const latest = listLatestLocationForCustomerFacingPiece(
            result.locationHistory
          );
          console.log("latest: ", latest);
          setArtPieceInformation({
            ...result,
            location: latest.location,
          });
        } else {
          setArtPieceInformation(result);
        }
      }
    } catch (error) {
      console.error("Error updating piece:", error);
    }
  };

  useEffect(() => {
    const fetchPlaces = async () => {
      if (locationSearch.length > 1 && !hasSelectedLocation) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL
            }/autocomplete?input=${encodeURIComponent(locationSearch)}`
          );
          if (response.ok) {
            const data = await response.json();
            // Directly set the suggestions with the returned data if it's an array
            if (Array.isArray(data)) {
              setSuggestions(data);
            } else {
              // If data is not an array, log the issue and set suggestions to empty
              console.error("Unexpected response data:", data);
              setSuggestions([]);
            }
          } else {
            throw new Error("Failed to fetch data from the server");
          }
        } catch (error) {
          console.error("Failed to fetch places:", error);
        }
      } else {
        setSuggestions([]);
      }
    };

    if (!hasSelectedLocation) {
      const timeoutId = setTimeout(fetchPlaces, 300);
      return () => clearTimeout(timeoutId);
    }
  }, [locationSearch, hasSelectedLocation]);

  useEffect(() => {
    if (pieceId) {
      setIsLoading(true);
      fetchImage();
      // fetchArtistInformation();
    }
  }, [pieceId]);

  useEffect(() => {
    if (linkId) {
      setIsLoading(true);
      fetchLinkedImage();
      // fetchArtistInformation();
      setTaggedEntity(linkId?.substring(0, 6).toUpperCase() || "");
    }
  }, [linkId]);

  useEffect(() => {
    if (artPieceInformation?.artistUid) {
      fetchArtistInformation();
    }
  }, [artPieceInformation?.artistUid]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        await getUserByUid(uid);
      }
    });
  }, []);

  const getUserByUid = async (uid: string) => {
    try {
      const response = await fetch(
        `https://sparkplug-api.onrender.com/user/${uid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const userResponse = await response.json();
      setUser(userResponse);
      return userResponse;
    } catch (error) {
      console.log(error);
    }
  };

  // TODO: make this accessible by other components. Its being DRY'd
  const inputStyle = {
    padding: "10px",
    margin: "10px 0",
    backgroundColor: "#1f2129",
    color: "white",
    border: "1px solid #444",
    borderRadius: "500px",
    width: "-webkit-fill-available",
    fontFamily: "Arial, sans-serif",
  };

  const descriptionStyle = {
    ...inputStyle,
    fontSize: "16px",
    borderRadius: "10px",
    height: "100px",
  };

  const uploadRectangleStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #444",
    borderRadius: "10px",
    height: "200px",
    cursor: "pointer",
    color: "white",
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
  };

  const iconStyle = {
    fontSize: "48px",
    marginBottom: "10px",
  };

  const triggerModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const onSubmitClick = () => {
    return navigate(`/artpiece/${linkId}/comments`);
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const notify = () => {
    copyToClipboard(
      `https://artlink.network/artpiece/${linkId || artPieceInformation.linkId}`
    );
    toast("Link copied to clipboard!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  };

  const notifySavedPiece = () => {
    toast("Piece updated!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  };

  const handleDelete = () => {
    setIsDeleteModalVisible(true);
  };

  const handleDeletePhoto = () => {
    setIsDeletePhotoModalVisible(true);
  };

  const dimensionInputStyle = {
    ...inputStyle,
    flex: 1, // Make inputs take equal space
    // margin: "10px", // Adjust based on layout
    width: "65px", // Adjust based on layout
  };

  const errorDimensionInputStyle = {
    ...dimensionInputStyle,
    border: "1px solid red",
  };

  const deletePiece = async () => {
    // API Call to delete piece
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/piece/delete/${pieceId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (!response.ok) {
        console.error("Error deleting piece");
      }

      if (response.ok) {
        navigate("/delete-success");
      }
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  const deletePhoto = async () => {
    // API Call to delete photo of the current piece
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/piece/delete-photo/${pieceId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (!response.ok) {
        console.error("Error deleting photo");
      }

      if (response.ok) {
        setIsDeletePhotoModalVisible(false);
        setArtPieceInformation({
          ...artPieceInformation,
          photo: null,
        });
      }
    } catch (error) {
      console.error("Error deleting photo:", error);
    }
  };

  const base64ToBlob = (base64: string, contentType = "image/png") => {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };

  const handleSaveCroppedImage = async (croppedImage: any) => {
    const payload = {
      photo: croppedImage,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/piece/upload-photo/${pieceId}`,
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (!response.ok) {
        console.error("Error uploading photo");
      }

      if (response.ok) {
        setShowCropper(false);
        setArtPieceInformation({
          ...artPieceInformation,
          photo: croppedImage,
        });
      }
    } catch (error) {
      console.error("Error uploading photo:", error);
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          height: "100vh", // Take up the full viewport height
        }}
      >
        <Spin
          indicator={
            <LoadingOutlined style={{ fontSize: 100, color: "#7800FF" }} spin />
          }
        />
      </div>
    );
  }

  if (artPieceInformation === null) {
    return (
      <EmptyStatePiece
        mode={mode}
        triggerModal={triggerModal}
        isPublicFacing={isPublicFacing}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    );
  }

  const onLocationSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocationSearch(e.target.value);
    setHasSelectedLocation(false); // Allow searching again after the user starts typing
    // Add the location to the pieceInfomration
  };

  const selectPlace = (place: any) => {
    setArtPieceInformation({
      ...artPieceInformation,
      location: place,
    });
    setLocationSearch(place);
    setSuggestions([]); // Optionally clear suggestions after selection
    console.log("setting location to true");
    setHasSelectedLocation(true);
  };

  const onLocationClear = () => {
    setArtPieceInformation({
      ...artPieceInformation,
      location: null,
    });
    setLocationSearch("");
    setHasSelectedLocation(false);
  };

  const triggerConfirmationScreen = () => {
    navigate(`/artpiece/${linkId}/purchase`);
  };

  const handleSaveChanges = () => {
    updatePieceInformation();
    toggleMode();
  };

  const generatePricingStatus = () => {
    if (artPieceInformation.sold) {
      return <></>;
    } else {
      return (
        <>
          {/* <h2 id="medium">Listing Price</h2> */}
          <h2 id="pricey">
            Price:{" "}
            <div style={{ display: "inline-flex" }} id="price">
              {artPieceInformation.price
                ? "$" + formatPriceWithCommas(artPieceInformation.price)
                : "Not For Sale"}
            </div>
          </h2>
        </>
      );
    }
  };

  const createTag = async () => {
    const { dmnShortenedId, linkedUrl } = generateUrl(
      DMN_URL,
      artPieceInformation.linkId
    );
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: [127, 203.2],
    });

    doc.addFileToVFS("roadgeek-2005-engschrift.ttf", exportedFont);
    doc.addFont("roadgeek-2005-engschrift.ttf", "roadgeek", "normal");
    doc.setFont("roadgeek", "normal");

    doc.setTextColor(0, 0, 0);

    doc.addImage(newerDmnLogo, "PNG", 5, 5, 40, 40); // Adds image at (10, 10) with dimensions 40x40
    doc.addImage(artLinkLogo, "PNG", 10, 49, 25.8, 6); // Adds artLink logo (Original Dimensions 734X171 - Used Dimensions 25.8X6)
    try {
      const qrCodeDataURL = await generateStyledQRCode(linkedUrl);
      // Add the QR code to the PDF at the bottom left
      doc.addImage(qrCodeDataURL, "PNG", 7, 85, 38, 38);
      // const qrCodeDataURL = await QRCode.toDataURL(linkedUrl);
      // // Add the QR code to the PDF at the bottom left
      // doc.addImage(qrCodeDataURL, "PNG", 3, 80, 45, 45);
    } catch (err) {
      console.error("Could not generate QR code", err);
    }

    const titleElement = document.getElementById("title");
    const title = titleElement ? getElementTextContent(titleElement) : "";
    const artist = document.getElementById("artist")?.innerText || "";
    const size = document.getElementById("size")?.innerText || "";
    const medium = document.getElementById("medium")?.innerText || "";
    const price = document.getElementById("price")?.innerText || "";
    // Strip out "By " from the artist name
    const artistName = artist.replace("By ", "");

    // Add other fields such as price based on their ids
    doc.setFontSize(36);
    // Position text to the right of the logo, adjust the x-coordinate
    doc.text(`Title: ${title}`, 70, 25);
    doc.text(`Artist: ${artistName}`, 70, 45);
    doc.text(`Medium: ${medium}`, 70, 65);
    doc.text(`Size: ${size}`, 70, 85);
    doc.text(`Price: ${price}`, 70, 105);
    // Add other fields such as price
    doc.setFontSize(24);

    doc.setCharSpace(-0.25);
    doc.text("Connect, engage,", 8, 65);
    doc.text("converse, support,", 8, 73);
    doc.text("purchase:", 8, 81);

    doc.text(dmnShortenedId, 150, 120);

    const blob = doc.output("blob");
    const blobURL = URL.createObjectURL(blob);

    // This is for mobile devices
    if (
      navigator &&
      navigator.share &&
      /Mobi|Android/i.test(navigator.userAgent)
    ) {
      // Use the navigator.share API for mobile devices if available
      const file = new File([blob], `${dmnShortenedId.substring(1)}.pdf`, {
        type: "application/pdf",
      });
      navigator
        .share({
          title: "Tag",
          text: "Download your tag",
          files: [file],
        })
        .catch((error) => console.error("Sharing failed:", error));
    } else {
      // create a temporary link element
      const link = document.createElement("a");

      // set attributes and href for the link. This will define the file name for the download
      link.href = blobURL;
      link.download = `${dmnShortenedId.substring(1)}.pdf`; // or any other name you wish to give

      // append the link to the body
      document.body.appendChild(link);

      // if on mobile device, use the click event to trigger the download

      // trigger download
      link.click();

      // clean up the DOM
      document.body.removeChild(link);
      URL.revokeObjectURL(blobURL);
    }
  };

  const ogMetadata = generateOGMetadata(
    artPieceInformation,
    artistInformation,
    taggedEntity
  );
  console.log("artPieceInformation: ", artPieceInformation);
  return (
    <div
      style={{
        position: "relative",
        color: "white",
        padding: "20px",
        margin: "10px", // Added margin around the entire component
        paddingBottom: "70px",
        paddingTop: "20px",
      }}
    >
      <Helmet>
        <title>{`ArtLink - ${artPieceInformation?.name}`}</title>
        {/* <meta property="og:title" content={artPieceInformation?.name} />
        <meta
          property="og:image"
          content={
            artPieceInformation.photo || "https://i.imgur.com/dlJvkM6.png"
          }
        /> */}
      </Helmet>
      {/* <Helmet>
        <title>
          {artPieceInformation?.name} by {artistInformation.displayName} -
          ArtLink
        </title>
        <meta property="og:title" content={artPieceInformation?.name} />
        <meta
          property="og:description"
          content={`Item Number: ${`#DMN-${taggedEntity}`}, PieceType, Size: Size, Location: Location, Price: ${
            artPieceInformation.price
          }`}
        />
        <meta
          property="og:image"
          content={
            artPieceInformation.photo || "https://i.imgur.com/dlJvkM6.png"
          }
        />
        <meta
          property="og:url"
          content="https://www.yourwebsite.com/piece-page"
        />
      </Helmet> */}
      <ToastContainer />
      <FontAwesomeIcon
        style={{
          display: isPublicFacing ? "none" : "block",
          marginRight: "auto",
          marginLeft: "10px",
          marginBottom: "10px",
        }}
        size="xl"
        icon={faAngleLeft}
        color="white"
        onClick={() => {
          if (isPublicFacing) {
            navigate(-1);
          } else {
            navigate("/home");
          }
        }}
      />
      {artPieceInformation.quantity === 0 && <RibbonTest />}
      {/* Container to center the image */}
      {artPieceInformation.photo ? (
        <>
          <ResponsiveImage
            isSold={artPieceInformation.sold}
            src={artPieceInformation.photo}
            alt="example"
            // @ts-ignore
            onToggle={() => setToggler(!toggler)}
          />
          <FsLightbox toggler={toggler} sources={[artPieceInformation.photo]} />
        </>
      ) : (
        <div style={uploadRectangleStyle} onClick={() => setShowCropper(true)}>
          <div style={iconStyle}>
            <AiOutlinePicture color="gray" />
          </div>
          Upload Photo
        </div>
      )}
      {/* Delete button */}
      {artPieceInformation.photo && (
        <div
          style={{
            display: "flex",
            justifyContent: "start",
          }}
        >
          <button
            style={{
              display: mode === "view" ? "none" : "block",
              fontWeight: "bold",
              width: "125px",
              backgroundColor: "#7800ff",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={handleDeletePhoto}
          >
            Delete Photo
          </button>
        </div>
      )}
      {/* Text and Icon (left-aligned) */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          justifyContent: "space-between", // Added this
        }}
      >
        <div
          style={{
            display: "flex",
            // alignItems: "flex-start",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              minWidth: 0,
            }}
          >
            {mode === "view" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    // alignItems: "center",
                    flexWrap: "nowrap",
                    flex: 1,
                    minWidth: 0,
                  }}
                >
                  <h2
                    id="title"
                    style={{
                      margin: 0,
                      marginRight: "10px",
                      // flex: 1,
                      wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                      overflow: "hidden", // Ensures any overflow is hidden
                    }}
                  >
                    {artPieceInformation?.name}
                    <div
                      id="viewIcon"
                      style={{
                        display: "inline-flex",
                        marginLeft: "5px",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      <ViewIcon />
                      <div
                        id="totalViews"
                        style={{
                          display: "inline-flex",
                          position: "relative",
                          fontSize: "smaller",
                          marginLeft: "5px",
                        }}
                      >
                        {getTotalViews(artPieceInformation) || "0"}
                      </div>
                    </div>
                  </h2>
                </div>
              </>
            ) : (
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => {
                  setArtPieceInformation({
                    ...artPieceInformation,
                    name: e.target.value,
                  });
                }}
                value={artPieceInformation.name}
                style={{ ...inputStyle, marginRight: "10px", fontSize: "16px" }}
              />
            )}
          </div>
          {!isPublicFacing && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              {mode === "view" ? (
                <div style={{ marginTop: "7px" }}>
                  <BsFillPencilFill
                    onClick={toggleMode}
                    color="white"
                    size={14}
                    style={{ marginLeft: "10px" }}
                  />
                  <FaRegTrashCan
                    onClick={handleDelete}
                    color="white"
                    size={14}
                    style={{ marginLeft: "10px" }}
                  />
                </div>
              ) : (
                <BsCheck2Circle
                  onClick={handleSaveChanges}
                  color="white"
                  size={28}
                  style={{ marginLeft: "10px" }}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {taggedEntity && (
        <div
          style={{
            display: "flex",
            alignItems: "center", // Aligns items vertically in the center
            justifyContent: "flex-start", // Aligns items to the start of the container
          }}
        >
          <h4
            id="taggedEntity"
            style={{
              margin: 0,
              color: "#7800FF",
              marginRight: "10px", // Adds some space between the text and the icon
              marginTop: "10px",
            }}
          >
            {`#ART-${taggedEntity}`}
          </h4>
          <FontAwesomeIcon
            onClick={() => notify()}
            icon={faLink}
            color="white"
            size="sm"
            style={{ alignSelf: "center", marginTop: 10 }} // Centers the icon if it's not aligned with the text
          />
        </div>
      )}
      <h4
        id="artist"
        style={{ marginTop: 10 }}
        onClick={() => {
          if (currentUser?.uid === artistInformation?.uid) {
            navigate("/artist-setup-page");
          } else {
            navigate(`/artist/${artistInformation?.uid}`, {
              state: {
                fromPiece: true,
              },
            });
          }
        }}
      >
        <span style={{ textDecoration: "none" }}>By</span>{" "}
        <span style={{ textDecoration: "underline" }}>
          {artistInformation?.displayName ||
            artistInformation?.name ||
            "Artist Name"}
        </span>
      </h4>
      <h2 id="pieceDetails">Piece Details:</h2>
      <div style={{ marginBottom: "10px" }}>
        <div style={{ marginLeft: "10px", marginTop: "10px" }}>
          {mode === "view" ? (
            artPieceInformation?.type.map((type: string, index: number) => {
              return (
                <Tag id="medium" style={{ fontWeight: "bold" }} color="#7800FF">
                  {type}
                </Tag>
              );
            })
          ) : (
            <Select
              className="pieceType"
              mode="tags"
              style={{ ...inputStyle, fontSize: "16px" }}
              placeholder="Select type of art piece"
              defaultValue={artPieceInformation.type}
              onChange={(e) => {
                setArtPieceInformation({
                  ...artPieceInformation,
                  type: e,
                });
              }}
              options={pieceOptions}
              optionRender={(option) => (
                <Space>
                  <span role="img" aria-label={option.data.label}>
                    {option.data.emoji}
                  </span>
                  {option.data.label}
                </Space>
              )}
            />
          )}
        </div>
      </div>
      {mode === "view" ? (
        <>
          {/* <h2 id="medium">Piece Dimensions</h2> */}
          <h4 id="size" style={{ margin: 0, paddingLeft: "10px" }}>
            {artPieceInformation?.height &&
              `${artPieceInformation?.height || ""} x ${artPieceInformation?.width || ""
              } x  ${artPieceInformation?.depth || ""} ${artPieceInformation?.units || ""
              }`}
          </h4>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            margin: "10px 0",
          }}
        >
          <input
            value={artPieceInformation.height}
            onChange={(e) =>
              setArtPieceInformation({
                ...artPieceInformation,
                height: e.target.value,
              })
            }
            type="tel"
            placeholder="Height"
            style={{ ...dimensionInputStyle, fontSize: "16px" }}
            onInput={(e) => {
              // @ts-ignore
              e.target.value = e.target.value.replace(/[^0-9.]/g, "");
            }}
          />
          <input
            value={artPieceInformation.width}
            onChange={(e) =>
              setArtPieceInformation({
                ...artPieceInformation,
                width: e.target.value,
              })
            }
            type="tel"
            placeholder="Width"
            style={{ ...dimensionInputStyle, fontSize: "16px" }}
            onInput={(e) => {
              // @ts-ignore
              e.target.value = e.target.value.replace(/[^0-9.]/g, "");
            }}
          />
          <input
            value={artPieceInformation.depth}
            onChange={(e) =>
              setArtPieceInformation({
                ...artPieceInformation,
                depth: e.target.value,
              })
            }
            type="tel"
            placeholder="Depth"
            style={{ ...dimensionInputStyle, fontSize: "16px" }}
            onInput={(e) => {
              // @ts-ignore
              e.target.value = e.target.value.replace(/[^0-9.]/g, "");
            }}
          />
          <select
            onChange={(e) =>
              setArtPieceInformation({
                ...artPieceInformation,
                units: e.target.value,
              })
            }
            value={artPieceInformation.units}
            //@ts-ignore
            placeholder="Units"
            // style={dimensionInputStyle}
            style={
              validationError ? errorDimensionInputStyle : dimensionInputStyle
            }
          >
            <option value="" disabled selected hidden>
              Select unit
            </option>
            <option value="in">inches</option>
            <option value="ft">feet</option>
            <option value="mm">mm</option>
            <option value="other">Other</option>
          </select>
        </div>
      )}
      {mode === "view" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
            marginLeft: "5px",
          }}
        >
          <FontAwesomeIcon
            icon={faLocationDot}
            style={{ marginRight: "5px" }}
          />
          <h4
            // should go to maps via location.geometry.location.lat and location.geometry.location.lng
            onClick={() => {
              if (
                listLatestLocationForCustomerFacingPiece(
                  artPieceInformation.locationHistory
                ).location.name !== "Artist's Collection"
              ) {
                window.open(
                  "https://maps.apple.com/?q=" +
                  artPieceInformation?.location?.geometry?.location?.lat +
                  "," +
                  artPieceInformation?.location?.geometry?.location?.lng
                );
              }
            }}
            id="size"
            style={{
              margin: 0,
              textDecoration: "underline",
              color: "#7800FF",
            }}
          >
            {/* {generateLatestLocationName(artPieceInformation.locationHistory)} */}
            {
              listLatestLocationForCustomerFacingPiece(
                artPieceInformation.locationHistory
              ).location.name
            }
          </h4>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            Location?
            <Switch
              onChange={(checked) => {
                setHasLocation(checked);
                setArtPieceInformation({
                  ...artPieceInformation,
                  location: checked ? {} : "",
                });
                if (!checked) {
                  setLocationSearch({
                    name: "Artist's Collection",
                  });
                } else {
                  setLocationSearch({
                    name: "",
                  });
                  setHasSelectedLocation(false);
                }
              }}
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={
                listLatestLocationForCustomerFacingPiece(
                  artPieceInformation.locationHistory
                ).location.name !== "Artist's Collection"
                  ? true
                  : false
              }
              style={{ backgroundColor: "#7800FF", marginLeft: "10px" }}
            />
          </div>
          <div style={{ position: "relative", width: "100%" }}>
            <LocationInput
              hasSelectedLocation={hasSelectedLocation}
              hasLocation={hasLocation}
              artPieceInformation={artPieceInformation}
              setArtPieceInformation={setArtPieceInformation}
              setLocationSearch={setLocationSearch}
              setHasSelectedLocation={setHasSelectedLocation}
              locationSearch={locationSearch}
              onLocationSearchChange={onLocationSearchChange}
              suggestions={suggestions}
              selectPlace={selectPlace}
              setSuggestions={setSuggestions}
              onLocationClear={onLocationClear}
            />
          </div>
        </div>
      )}
      {artPieceInformation.quantity &&
        (user.roleId === 2 || user.roleId === 3) && (
          <>
            {mode === "view" ? (
              <h4 id="description" style={{ margin: 0 }}>
                <p>Quantity: {artPieceInformation.quantity}</p>
              </h4>
            ) : (
              <Quantity
                quantity={artPieceInformation.quantity}
                setQuantity={(quantity) => {
                  setArtPieceInformation({
                    ...artPieceInformation,
                    quantity: quantity,
                  });
                }}
              />
            )}
          </>
        )}
      {mode === "view" ? (
        <>{generatePricingStatus()}</>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
            >
              For Sale?
              <Switch
                onChange={(e) => {
                  setIsForSale(e);
                  if (e === false) {
                    setArtPieceInformation({
                      ...artPieceInformation,
                      price: null,
                    });
                  } else {
                    setArtPieceInformation({
                      ...artPieceInformation,
                      price: "",
                    });
                  }
                }}
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked={artPieceInformation.price ? true : false}
                style={{ backgroundColor: "#7800FF", marginLeft: "10px" }}
              />
            </div>
            <InputNumber<number>
              disabled={isForSale ? false : true}
              className="ant-input-number"
              style={{ ...inputStyle, color: "white", fontSize: "16px" }}
              defaultValue={artPieceInformation.price}
              value={isForSale ? artPieceInformation.price : "Not for Sale"}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) =>
                value?.replace(/\$\s?|(,*)/g, "") as unknown as number
              }
              onChange={(value) => {
                setArtPieceInformation({
                  ...artPieceInformation,
                  price: value,
                });
              }}
            />
          </div>
        </>
      )}
      <h4 id="medium" style={{ margin: 0 }}>
        Description:
      </h4>
      {mode === "view" ? (
        <h4 id="description" style={{ margin: 0 }}>
          {artPieceInformation.description}
        </h4>
      ) : (
        <textarea
          value={artPieceInformation.description}
          onChange={(e) => {
            setArtPieceInformation({
              ...artPieceInformation,
              description: e.target.value,
            });
          }}
          placeholder="Type"
          style={descriptionStyle}
        />
      )}
      <br />

      <h2 id="medium" style={{ margin: 0 }}>
        Locations (most recent):
      </h2>
      <br />
      {/* // sort by location.timestamp from latest at the top */}
      {artPieceInformation.locationHistory
        ?.sort(
          // @ts-ignore
          (a: any, b: any) => new Date(b.startDate) - new Date(a.startDate)
        )
        .map((location: any, index: number) => {
          return (
            <div key={index} style={{ marginBottom: "30px" }}>
              <h4 style={{ margin: 0 }}>
                {" "}
                <FontAwesomeIcon
                  icon={faLocationDot}
                  style={{ marginRight: "5px" }}
                />{" "}
                {location.location.name}
              </h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <ViewIcon />
                <span style={{ marginLeft: "5px" }}>
                  {location.views || "0"}
                </span>
              </div>
              <div style={{ marginTop: "5px" }}>
                <span
                  onClick={() => navigate(`/artpiece/${linkId}/comments`)}
                  style={{
                    cursor: "pointer",
                    // textDecoration: "underline",
                    // color: "#7800FF",
                  }}
                >
                  {countComments(artPieceInformation.comments, location.id) ||
                    "0"}{" "}
                  comment(s)
                </span>
              </div>
            </div>
          );
        })}
      {/* Button container */}
      <div
        style={{
          display: "flex",
          position: "fixed",
          justifyContent: "space-evenly",
          left: 0,
          bottom: 0,
          width: "100%",
          backgroundColor: "#1f2129",
          color: "white",
          textAlign: "center",
          padding: "10px 0", // Adjust as needed
          zIndex: 1000, // Ensure it's above other content
        }}
      >
        {isPublicFacing ? (
          // TODO: Put this in its component
          <>
            <button
              disabled={
                artPieceInformation.price === null || artPieceInformation.sold
              }
              style={{
                fontWeight: "bold",
                width: "125px",
                backgroundColor:
                  artPieceInformation.price === null || artPieceInformation.sold
                    ? "grey"
                    : "#7800ff",
                color: "white",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
              }}
              onClick={triggerConfirmationScreen}
            >
              {artPieceInformation.sold ? "Piece Sold" : "Buy"}
            </button>
            <button
              style={{
                fontWeight: "bold",
                width: "125px",
                backgroundColor: "#7800ff",
                color: "white",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
              }}
              onClick={onSubmitClick}
            >
              Discuss
            </button>
          </>
        ) : (
          <TagAndSubmitButtons
            createTag={createTag}
            triggerModal={triggerModal}
            mode={mode}
            handleSaveChanges={handleSaveChanges}
          />
        )}
      </div>
      {isModalVisible && (
        <LinkModal
          createTag={createTag}
          setTaggedEntity={setTaggedEntity}
          setArtPieceInformation={setArtPieceInformation}
          setIsModalVisible={setIsModalVisible}
          artPieceInformation={artPieceInformation}
        />
      )}
      {isDeleteModalVisible && (
        <DeletePieceModal
          setIsDeleteModalVisible={setIsDeleteModalVisible}
          deletePiece={deletePiece}
        />
      )}
      {isDeletePhotoModalVisible && (
        <DeletePiecePhotoModal
          setIsDeleteModalVisible={setIsDeletePhotoModalVisible}
          deletePhoto={deletePhoto}
        />
      )}
      <CropperModal
        visible={showCropper}
        onClose={() => setShowCropper(false)}
        onSave={handleSaveCroppedImage}
      />
    </div>
  );
};

export default PieceInformationWithId;
